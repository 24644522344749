import { MutableRefObject, Ref, useState } from "react";
import { Fail } from "../components";
import { useChat } from "../context/chat-context";
import { Flavour, Question, Template } from "../interface";
import { LongHero, ShortHero, XThingsHero } from "./Hero";
import { VideoHero } from "./Hero/VideoHero/VideoHero";
import { LongInform, ShortInform, XThingsInform } from "./Inform";
import { LongInspire, ShortInspire, XThingsInspire } from "./Inspire";
import { Preset } from "./Preset/Preset";

interface RetrieveTemplateProps {
  messageRef: MutableRefObject<{
    templateYScroll: number;
  }>;
  question: Question;
  index: number;
}

const RetrieveTemplate = ({
  question,
  index,
  messageRef,
}: RetrieveTemplateProps) => {
  const { loading, flavours } = useChat();

  return (
    <>
      {(() => {
        switch (flavours[index]) {
          case Flavour.fail: {
            return (
              <Fail
                message={messageRef.current}
                key={index}
                failText={
                  question.response?.content.paragraphs[0]?.text as string
                }
                templateOrderIndex={index}
                loading={loading}
              />
            );
          }
          case Flavour.hero: {
            switch (question.response.template) {
              case Template.preset_answer: {
                return (
                  <Preset
                    message={messageRef.current}
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    key={index}
                  />
                );
              }
              case Template.short: {
                return (
                  <ShortHero
                    message={messageRef.current}
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    key={index}
                    testingTemplates={false}
                  />
                );
              }
              case Template.long: {
                return (
                  <LongHero
                    message={messageRef.current}
                    loading={loading}
                    response={question.response}
                    key={index}
                  />
                );
              }
              case Template.three_parts: {
                return (
                  <XThingsHero
                    message={messageRef.current}
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response!}
                    key={index}
                  />
                );
              }
              case Template.video: {
                return (
                  <VideoHero
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    message={messageRef.current}
                  />
                );
              }
              default: {
                return;
              }
            }
          }
          case Flavour.inform: {
            switch (question.response.template) {
              case Template.short: {
                return (
                  <ShortInform
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    message={messageRef.current}
                    key={index}
                    testingTemplates={false}
                  />
                );
              }
              case Template.long: {
                return (
                  <LongInform
                    message={messageRef.current}
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    testingTemplates={false}
                    key={index}
                  />
                );
              }
              case Template.three_parts: {
                return (
                  <XThingsInform
                    templateOrderIndex={index}
                    message={messageRef.current}
                    loading={loading}
                    response={question.response}
                    key={index}
                    testingTemplates={false}
                  />
                );
              }
              default: {
                return;
              }
            }
          }
          case Flavour.inspire: {
            switch (question.response.template) {
              case Template.short: {
                return (
                  <ShortInspire
                    message={messageRef.current}
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    key={index}
                    testingTemplates={false}
                  />
                );
              }
              case Template.long: {
                return (
                  <LongInspire
                    message={messageRef.current}
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    key={index}
                    testingTemplates={false}
                  />
                );
              }
              case Template.three_parts: {
                return (
                  <XThingsInspire
                    message={messageRef.current}
                    templateOrderIndex={index}
                    loading={loading}
                    response={question.response}
                    key={index}
                    testingTemplates={false}
                  />
                );
              }
              default: {
                return;
              }
            }
          }
        }
      })()}
    </>
  );
};
export default RetrieveTemplate;
