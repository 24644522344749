import React, { Ref, forwardRef, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Grid } from "../../../components";
import Citations from "../../../components/Citations/Citations";
import { QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface ShortHeroProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  testingTemplates: boolean;
  message: {
    templateYScroll: number;
  };
}

export const ShortHero = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      testingTemplates,
      message,
      loading,
    }: ShortHeroProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    const [templateColour, setTemplateColour] = useState("white");
    useEffect(() => {
      if (testingTemplates) {
        if (templateColour === "white") {
          setVersion("white");
        }
        if (templateColour === "dark") {
          setVersion("dark");
        }
        if (templateColour === "grey") {
          setVersion("grey");
        }
      }
    }, [templateColour]);

    return (
      <SharedTemplate
        id="short-hero"
        textColor={version}
        className={classNames("desktop:rounded-t-[1.5rem]", {
          ["rounded-t-[1.5rem]"]: templateOrderIndex !== 0,
        })}
        templateRef={templateRef}
      >
        <div className="overflow-hidden rounded-t-[1.5rem]">
          <Grid customClasses="gap-x-4 relative desktop:!mx-0 rounded-t-[1.5rem]">
            <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-23 desktop:col-span-16 w-full h-full mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] desktop:pb-[42px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 pb-[5.5rem]">
              <h2
                id="short-hero-tagline"
                className="mobile:text-[1rem] desktop:text-lg font-medium uppercase"
              >
                {query}
              </h2>
              {testingTemplates && (
                <ColourSelect
                  templateColour={templateColour}
                  setTemplateColour={setTemplateColour}
                ></ColourSelect>
              )}
              <div className="pt-[36px]">
                {!content.header ? (
                  <TitleSkeleton />
                ) : (
                  <h3
                    id="short-hero-header"
                    className="mobile:text-4xl desktop:text-[40px] font-medium"
                  >
                    {content.header}
                  </h3>
                )}
              </div>
            </div>
            <div
              id="short-hero-container"
              className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-24"
            >
              {!content.imgURL ? (
                <div>
                  <div className="mobile:hidden tablet:block desktop:block">
                    <ImageSkeleton display="hero-short" />
                  </div>
                  <div className="tablet:hidden desktop:hidden">
                    <ImageSkeleton display="hero-short-mobile" />
                  </div>
                </div>
              ) : (
                <>
                  {!content.imgURL ? (
                    <div className="w-full mobile:h-[250px] tablet:h-[400px] max-h-[400px] bg-gradient-to-b from-[#8B919A] to-white"></div>
                  ) : (
                    <div
                      id="short-hero-image"
                      style={{
                        backgroundImage: `url('${content.imgURL}')`,
                      }}
                      className={`z-3 w-full mobile:h-[250px] tablet:h-[400px] max-h-[400px] bg-cover bg-center bg-no-repeat`}
                    ></div>
                  )}
                </>
              )}
            </div>

            <div
              id="short-hero-answer-content"
              className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-23 desktop:col-span-16 w-full h-full mobile:pt-[1.375rem] desktop:pt-[1.75rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:pb-[72px]"
            >
              <div
                id="short-hero-paragraph-content-1"
                className="pt-[1.375rem]"
              >
                {!content.paragraphs[0] ? (
                  <ParagraphSkeleton />
                ) : (
                  <TypewriterText
                    id="short-hero-paragraph-1"
                    className="mobile:text-sm desktop:text-lg font-light"
                    hasAllText={content.paragraphs[0].done}
                    text={
                      content.paragraphs[0].text[
                        content.paragraphs[0].text.length - 1
                      ] === "."
                        ? content.paragraphs[0].text
                        : `${content.paragraphs[0].text}.`
                    }
                    isDone={para1Done}
                    setIsDone={setPara1Done}
                  />
                )}
              </div>
            </div>
          </Grid>
          <div data-testid="media-links-v2" className="hidden" />
          {para1Done && (
            <>
              <Citations
                metadata={response.metadata}
                version={version}
                usedSnippetIds={response.used_snippet_ids}
              />
              <MediaLinksV2
                assets={assets}
                display="fullwidth"
                version={version}
              />
            </>
          )}
        </div>
      </SharedTemplate>
    );
  }
);
