interface ColourSelectProps {
  templateColour: string;
  setTemplateColour: (value: string) => void;
}

const ColourSelect = ({
  templateColour,
  setTemplateColour,
}: ColourSelectProps) => {
  const handleColourChange = (colour: string) => {
    setTemplateColour(colour);
  };

  return (
    <select
      name="category"
      value={templateColour}
      onChange={(event) => handleColourChange(event.target.value)}
      className="z-50 bg-white text-black"
    >
      <option id="white" value="white">
        White
      </option>
      <option id="dark" value="dark">
        Dark
      </option>
      <option id="grey" value="grey">
        Grey
      </option>
    </select>
  );
};

export default ColourSelect;
