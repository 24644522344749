import React from "react";

interface DownloadProps {
  colour: string;
}

export const Download = ({ colour }: DownloadProps) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Download ">
      <g id="Shape">
        <path
          className={colour}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.125 13.2917C3.53921 13.2917 3.875 13.6275 3.875 14.0417V15.7083C3.875 16.2145 4.28546 16.625 4.79167 16.625H15.2083C15.7145 16.625 16.125 16.2145 16.125 15.7083V14.0417C16.125 13.6275 16.4608 13.2917 16.875 13.2917C17.2892 13.2917 17.625 13.6275 17.625 14.0417V15.7083C17.625 17.043 16.543 18.125 15.2083 18.125H4.79167C3.45704 18.125 2.375 17.043 2.375 15.7083V14.0417C2.375 13.6275 2.71079 13.2917 3.125 13.2917Z"
          fill-opacity="0.5"
        />
        <path
          className={colour}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 2.875C10.4142 2.875 10.75 3.21079 10.75 3.625V13.2083C10.75 13.6225 10.4142 13.9583 10 13.9583C9.58579 13.9583 9.25 13.6225 9.25 13.2083V3.625C9.25 3.21079 9.58579 2.875 10 2.875Z"
          fill-opacity="0.5"
        />
        <path
          className={colour}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.96967 11.0113C7.26256 10.7184 7.73744 10.7184 8.03033 11.0113L10 12.981L11.9697 11.0113C12.2626 10.7184 12.7374 10.7184 13.0303 11.0113C13.3232 11.3042 13.3232 11.7791 13.0303 12.072L10.5303 14.572C10.2374 14.8649 9.76256 14.8649 9.46967 14.572L6.96967 12.072C6.67678 11.7791 6.67678 11.3042 6.96967 11.0113Z"
          fill-opacity="0.5"
        />
      </g>
    </g>
  </svg>
);
