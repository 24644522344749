import React, { useEffect, useRef, ReactNode, CSSProperties } from 'react';

interface ScrollForwarderProps {
  /**
   * Ref to the scrollable container.
   * Should be created using `useRef` in the parent component and passed down.
   */
  scrollableRef: React.RefObject<HTMLElement>;
  
  /**
   * The text elements to wrap and make interactive.
   * Typically, these would be `<p>`, `<span>`, `<div>`, etc.
   */
  children: ReactNode;
  
  /**
   * Optional additional class names for styling.
   */
  className?: string;
  
  /**
   * Optional inline styles.
   */
  style?: CSSProperties;
}

const ScrollForwarder: React.FC<ScrollForwarderProps> = ({
  scrollableRef,
  children,
  className = '',
  style = {},
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Refs to track touch state
  const isTouching = useRef<boolean>(false);
  const touchStartY = useRef<number>(0);
  const touchStartX = useRef<number>(0);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const scrollable = scrollableRef.current;

    // Ensure both refs are attached
    if (!wrapper || !scrollable) return;

    /**
     * Handle Wheel Events
     *
     * Captures mouse wheel scrolls over the wrapped element and forwards them to the scrollable container.
     *
     * @param {WheelEvent} e - The wheel event object.
     */
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault(); // Prevent default scrolling behavior on the wrapped element

      // Forward scroll delta to the scrollable container
      scrollable.scrollBy({
        top: e.deltaY,
        left: e.deltaX,
        behavior: 'auto', // Immediate scrolling without animation
      });
    };

    /**
     * Handle Touch Start
     *
     * Records the initial touch position when a touch starts.
     *
     * @param {TouchEvent} e - The touchstart event object.
     */
    const handleTouchStart = (e: TouchEvent) => {
      if (e.touches.length === 1) { // Only handle single-touch
        isTouching.current = true;
        touchStartY.current = e.touches[0].clientY;
        touchStartX.current = e.touches[0].clientX;
      }
    };

    /**
     * Handle Touch Move
     *
     * Calculates the movement delta and scrolls the scrollable container accordingly.
     *
     * @param {TouchEvent} e - The touchmove event object.
     */
    const handleTouchMove = (e: TouchEvent) => {
      if (!isTouching.current) return;

      const touch = e.touches[0];
      const deltaY = touch.clientY - touchStartY.current; // Change in Y-axis
      const deltaX = touch.clientX - touchStartX.current; // Change in X-axis

      // Update the starting positions for the next touchmove event
      touchStartY.current = touch.clientY;
      touchStartX.current = touch.clientX;

      // Forward scroll delta to the scrollable container
      scrollable.scrollBy({
        top: -deltaY, // Negative to match scroll direction
        left: -deltaX,
        behavior: 'auto', // Immediate scrolling
      });

      e.preventDefault(); // Prevent native scrolling
    };

    /**
     * Handle Touch End
     *
     * Resets the touch state when the touch ends.
     */
    const handleTouchEnd = () => {
      isTouching.current = false; // Reset touch state
    };

    // Attach event listeners to the wrapped element
    wrapper.addEventListener('wheel', handleWheel, { passive: false });
    wrapper.addEventListener('touchstart', handleTouchStart, { passive: true });
    wrapper.addEventListener('touchmove', handleTouchMove, { passive: false });
    wrapper.addEventListener('touchend', handleTouchEnd);

    // Cleanup event listeners on component unmount
    return () => {
      wrapper.removeEventListener('wheel', handleWheel);
      wrapper.removeEventListener('touchstart', handleTouchStart);
      wrapper.removeEventListener('touchmove', handleTouchMove);
      wrapper.removeEventListener('touchend', handleTouchEnd);
    };
  }, [scrollableRef]); // Re-run effect if scrollableRef changes

  // Inline styles for the wrapper to ensure pointer events are handled correctly
  const wrapperStyle: CSSProperties = {
    pointerEvents: 'auto', // Allow interaction with the wrapped element
    userSelect: 'text', // Enable text selection
    ...style, // Spread any additional styles passed via props
  };

  return (
    <div ref={wrapperRef} className={`scroll-forwarder ${className}`} style={wrapperStyle}>
      {children}
    </div>
  );
};

export default ScrollForwarder;
