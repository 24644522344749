import React from "react";

interface ArrowDownIconProps {
  arrowColour: string;
}

export const ArrowDownIcon = ({ arrowColour }: ArrowDownIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="26"
    viewBox="0 0 16 26"
    aria-label="arrow pointing down"
  >
    <path
      className={arrowColour}
      d="M7.2929 25.7071C7.68342 26.0976 8.31658 26.0976 8.70711 25.7071L15.0711 19.3431C15.4616 18.9526 15.4616 18.3195 15.0711 17.9289C14.6805 17.5384 14.0474 17.5384 13.6569 17.9289L8 23.5858L2.34315 17.9289C1.95262 17.5384 1.31946 17.5384 0.928934 17.9289C0.538409 18.3195 0.538409 18.9526 0.928934 19.3431L7.2929 25.7071ZM7 8.74228e-08L7 25L9 25L9 -8.74228e-08L7 8.74228e-08Z"
    />
  </svg>
);
