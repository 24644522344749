import React from "react";

interface ArrowRightIconProps {
  arrowColour: string;
}
export const ArrowRightIcon = ({ arrowColour }: ArrowRightIconProps) => (
  <svg
    width={screen.width < 1000 ? 20 : 24}
    height={screen.width < 1000 ? 20 : 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Arrow pointing right"
  >
    <g id="enter icon">
      <circle id="Ellipse 1" cx="12" cy="12" r="12" fill="white" />
      <g id="Shape">
        <path
          d="M12.7328 7.56832C12.5219 7.35744 12.18 7.35744 11.9691 7.56832C11.7583 7.7792 11.7583 8.12111 11.9691 8.33199L15.2464 11.6092H7.24911C6.95087 11.6092 6.70911 11.851 6.70911 12.1492C6.70911 12.4475 6.95087 12.6892 7.24911 12.6892H15.247L11.969 15.9694C11.7582 16.1803 11.7583 16.5223 11.9693 16.7331C12.1802 16.9439 12.5221 16.9438 12.7329 16.7328L16.932 12.5309C16.934 12.529 16.9359 12.527 16.9378 12.525C17.0936 12.3644 17.1298 12.1309 17.0465 11.9364C17.0229 11.8813 16.9897 11.8294 16.947 11.7831C16.9883 11.8279 17.0221 11.8796 17.0465 11.9364"
          className={arrowColour}
        />
        <path
          d="M12.7328 7.56832L16.9302 11.7657L12.7328 7.56832Z"
          className={arrowColour}
        />
      </g>
    </g>
  </svg>
);
