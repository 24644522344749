import StreamedJsonToObjectTransformer from "../utils/stream-transformer";
import { Chunk, Config } from "../interface";

const stream = async ({
  config,
  conversation_id,
  user_id,
  query,
  seen_images,
  is_selected_follow_up,
  onChunk,
  onDone,
  onError,
  setController,
}: {
  config: Config;
  conversation_id: string;
  user_id: string;
  query: string;
  seen_images: string[];
  is_selected_follow_up: boolean;
  onChunk: (chunk: Chunk) => void;
  onDone: () => void;
  onError: () => void;
  setController: React.Dispatch<React.SetStateAction<AbortController | null>>;
}) => {
  const controller = new AbortController();
  const signal = controller.signal;
  setController(controller);
  try {
    const streamedJsonToObjectStream = new TransformStream(
      new StreamedJsonToObjectTransformer()
    );
    const response = await fetch(
      `${process.env.REACT_APP_SATALIA_API}/chat/generate?client_id=${config.CLIENT_ID}&site_id=${config.SITE_ID}&stream=true`,
      {
        signal,
        method: "POST",
        headers: {
          "Api-Key": config.SATALIA_API_KEY,
          "Content-Type": "application/json",
          responseType: "stream",
        },
        body: JSON.stringify({
          conversation_id,
          is_selected_follow_up,
          user_id,
          query: query
            .replaceAll(" you ", ` ${config.BRAND_NAME} `)
            .replaceAll(" You ", ` ${config.BRAND_NAME} `)
            .replaceAll(" YOU ", ` ${config.BRAND_NAME} `),
          seen_images,
        }),
      }
    );
    const rs = response.body;
    if (rs) {
      const jsonStream = rs.pipeThrough(streamedJsonToObjectStream);
      const reader: ReadableStreamDefaultReader = jsonStream.getReader();

      while (true) {
        const { done, value } = await reader?.read();
        if (!done) {
          onChunk((value as any).chunk as Chunk);
        }
        if (done) {
          setController(null);
          onDone();
          return;
        }
      }
    }
  } catch (e) {
    console.log("API ERROR: ", e);
    onError();
  }
};

export default stream;
