import { ArrowDownIcon } from "../../components/Icons";

interface ButtonScrollDown {
  customClasses: string;
  versionColour: string;
}

export const ButtonScrollDown = ({
  customClasses,
  versionColour,
}: ButtonScrollDown) => {
  const pageHeight = window.innerHeight;
  const scrollDown = () => {
    window.scrollBy({
      top: pageHeight,
      behavior: "smooth",
    });
  };

  return (
    <div id="button-scroll-down">
      {versionColour === "white" && (
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:rounded`}
            aria-label="Scroll down"
          >
            <ArrowDownIcon arrowColour="fill-icons-arrowdowndark" />
          </button>
          <span className="pl-8 w-48 text-base">
            Keep scrolling for more content
          </span>
        </div>
      )}
      {versionColour === "grey" && (
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:rounded`}
            aria-label="Scroll down"
          >
            <ArrowDownIcon arrowColour="fill-icons-coloarrowdowndark" />
          </button>
          <span className="pl-8 w-48 text-base">
            Keep scrolling for more content
          </span>
        </div>
      )}
      {versionColour === "dark" && (
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:rounded`}
            aria-label="Scroll down"
          >
            <ArrowDownIcon arrowColour="fill-icons-arrowdownlight" />
          </button>
          <span className="pl-8 w-48 text-base text-white">
            Keep scrolling for more content
          </span>
        </div>
      )}
      {versionColour === "image" && (
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:rounded`}
            aria-label="Scroll down"
          >
            <ArrowDownIcon arrowColour="fill-icons-arrowdownlight" />
          </button>
          <span className="pl-8 w-48 text-base text-white">
            Keep scrolling for more content
          </span>
        </div>
      )}
      {versionColour === "arrowOnly" && (
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:rounded`}
            aria-label="Scroll down"
          >
            <ArrowDownIcon arrowColour="fill-icons-arrowdownlight" />
          </button>
        </div>
      )}
      {versionColour === "textOnly" && (
        <div className="flex">
          <button
            type="button"
            onClick={scrollDown}
            className={`${customClasses} focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:rounded`}
            aria-label="Scroll down"
          >
            <span className="w-48 text-base text-white">
              Keep scrolling for more content
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
