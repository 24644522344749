import { useState, useEffect, createElement } from "react";
import { useChat } from "../../context/chat-context";

interface TypewriterTextProps {
  id?: string;
  text?: string;
  type?: string;
  showCursor?: boolean;
  className?: string;
  typeDelay?: number;
  isDone: boolean;
  hasAllText: boolean;
  setIsDone: (val: boolean) => void;
}

export function TypewriterText({
  text = "",
  type = "p",
  className = "",
  showCursor = true,
  typeDelay = 1,
  id,
  isDone,
  setIsDone,
  hasAllText,
}: TypewriterTextProps) {
  const [writtenText, setWrittenText] = useState("");
  const { resumed } = useChat();

  useEffect(() => {
    const newLength = writtenText.length + 1;

    if (hasAllText && newLength === text.length) {
      setIsDone(true);
    }

    const timeout = setTimeout(() => {
      setWrittenText(text.slice(0, newLength));
    }, typeDelay);

    return () => clearTimeout(timeout);
  }, [text, writtenText, typeDelay]);

  const elementClassNames =
    !isDone && showCursor
      ? [className, "blinking-cursor"].join(" ").trim()
      : className.trim();

  if (resumed)
    return createElement(
      type,
      { className: elementClassNames, id, "data-testid": "typewriter" },
      text
    );
  return createElement(
    type,
    { className: elementClassNames, id, "data-testid": "typewriter" },
    writtenText
  );
}

export default TypewriterText;
