import { forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import { QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import { useChat } from "../../../context/chat-context";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface LongHeroProps {
  loading: boolean;
  response: QuestionResponse;
  message: {
    templateYScroll: number;
  };
}

export const LongHero = forwardRef(({ response, message }: LongHeroProps) => {
  const { resumed } = useChat();
  const { content, query, assets } = response;
  const [para1Done, setPara1Done] = useState(resumed || false);
  const [para2Done, setPara2Done] = useState(resumed || false);
  const templateRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (message?.templateYScroll !== undefined && templateRef.current) {
      templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
    }
  }, [message]);

  return (
    <SharedTemplate
      id="long-hero"
      className="bg-gradient-to-b from-[#E0CCFF] from-25% to-white"
      templateRef={templateRef}
    >
      <div className="bg-no-repeat bg-center bg-cover rounded-t-[1.5rem]">
        <div className="text-black h-full w-full rounded-t-[1.5rem] relative">
          <Grid customClasses="gap-x-4 relative desktop:!mx-0 desktop:pb-[2.5rem] rounded-t-[1.5rem]">
            <div
              id="long-hero-tagline-content"
              className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-22 mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] desktop:row-start-1 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
            >
              <h2
                id="long-hero-tagline"
                className="mobile:text-[1rem] desktop:text-lg font-medium uppercase"
              >
                {query}
              </h2>
            </div>
            <div
              id="long-hero-header-content"
              className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-13 pt-[36px] desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
            >
              {!content.header ? (
                <TitleSkeleton />
              ) : (
                <h3
                  id="long-hero-header"
                  className="text-text-greyHeader mobile:text-4xl desktop:text-[40px] font-bold"
                >
                  {content.header}
                </h3>
              )}
            </div>
            <div
              id="long-hero-subheading-content"
              className="mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-24 pt-[36px] desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
            >
              {!content.tagline ? (
                <ParagraphSkeleton />
              ) : (
                <h4
                  id="long-hero-subheading"
                  className="text-text-greyText font-light mobile:text-xl desktop:text-2xl tablet:text-xl"
                >
                  {content.tagline}
                </h4>
              )}
            </div>

            <div
              id="long-hero-paragraph-content-1"
              className="text-text-greyText desktop:min-h-[200px] mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[1.875rem] desktop:row-start-3 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
            >
              {!content.paragraphs[0] ? (
                <ParagraphSkeleton />
              ) : (
                <TypewriterText
                  id="long-hero-paragraph-1"
                  className="desktop:text-lg font-light mobile:text-sm tablet:text-sm"
                  hasAllText={content.paragraphs[0].done}
                  text={
                    content.paragraphs[0].text[
                      content.paragraphs[0].text.length - 1
                    ] === "."
                      ? content.paragraphs[0].text
                      : `${content.paragraphs[0].text}.`
                  }
                  isDone={para1Done}
                  setIsDone={setPara1Done}
                />
              )}
            </div>

            <div
              id="long-hero-paragraph-content-2"
              className="text-text-greyText desktop:min-h-[200px] mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-24 mobile:pt-[22px] tablet:pt-[1.375rem] desktop:pt-[1.875rem] desktop:row-start-3 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
            >
              {!content.paragraphs[1] ? (
                <ParagraphSkeleton />
              ) : (
                para1Done && (
                  <TypewriterText
                    id="long-hero-paragraph-2"
                    className="desktop:text-lg font-light  mobile:text-sm tablet:text-sm"
                    text={content.paragraphs[1].text}
                    hasAllText={content.paragraphs[1].done}
                    isDone={para2Done}
                    setIsDone={setPara2Done}
                  />
                )
              )}
            </div>
          </Grid>

          <Citations
            metadata={response.metadata}
            version="light"
            usedSnippetIds={response.used_snippet_ids}
          />
          <MediaLinksV2 assets={assets} display="fullwidth" version="light" />
        </div>
      </div>
    </SharedTemplate>
  );
});
