import classNames from "classnames";
import { ReactNode, useRef } from "react";

interface MainContainerProps {
  open: boolean;
  handleModalClose: () => void;
  children: ReactNode;
}

const MainContainer = ({
  open,
  handleModalClose,
  children,
}: MainContainerProps) => {
  const ref = useRef(null);
  const handleOutsideModalClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === document.getElementById("mainContainer")) {
      handleModalClose();
    }
  };

  return (
    <div
      id="mainContainer"
      className={classNames({
        // "pt-[2.5%]": loading || questions.length,
        "animate-disappear": !open,
      })}
      ref={ref}
      onClick={(e) => handleOutsideModalClick(e)}
    >
      <div className="backgroundBlur" />
      <div
        className={classNames("fixed h-full w-full top-0", {
          "animate-expand": open,
          "animate-minify": !open,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default MainContainer;
