import { MutableRefObject, ReactNode } from "react";

import classNames from "classnames";
import { ContentRail } from "../components";

interface CollapseControlProps {
  children: ReactNode;
  className?: string;
  id: string;
  textColor?: string;
  templateRef?: MutableRefObject<HTMLDivElement | null>;
}

const SharedTemplate = ({
  children,
  className,
  templateRef,
  textColor = "white",
  id,
}: CollapseControlProps) => (
  <div
    id={id}
    className={classNames("relative z-40 pb-20", className, {
      "bg-white text-text-greyText": textColor === "white",
      "bg-grey-200 text-text-greyText": textColor === "grey",
      "bg-grey-1000 text-white": textColor === "dark",
    })}
    ref={templateRef}
  >
    {children}
    <ContentRail />
  </div>
);

// ["bg-white text-text-greyText"]: version === "white",
// ["bg-grey-200 text-text-greyText"]: version === "grey",
// ["bg-grey-1000 text-white"]: version === "dark",

export default SharedTemplate;
