import { useContext, useEffect, useRef, useState } from "react";
import InitialNew from "../pages/Initial/InitialNew";
import Interim from "../pages/Interim/Interim";
import MainContainer from "./MainContainer";
import { useChat } from "../context/chat-context";
import TemplateWrapper from "./TemplateWrapper";
import {
  animateFollowup,
  calculateScrollDistance,
  mountTemplate,
} from "../utils/template-animations";
import { FollowUpFullWidth } from "../components";
import { ModalContext } from "../hooks/useModal";
import CollapseControls from "./CollapseControls";

const DefaultLayout = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);
  const templateWrappers = useRef([]);
  const followupContainerRef = useRef(null);
  const newTemplateIdRef = useRef<null | number>(null);
  const individualScrollHeights = useRef<[]>([]);
  const { askQuestion, questions } = useChat();
  const context = useContext(ModalContext);
  const [templateCounter, setTemplateCounter] = useState(0);
  const [scrollTop, setScrollTop] = useState<number>(0);

  if (!context) return null;

  const handleModalClose = () => {
    context.closeModal();
    setTemplateCounter(0);
  };

  const handleOnSubmit = (value: string) => {
    if (templateCounter === questions.length) {
      askQuestion(value);
      const incrementCounter = templateCounter + 1;
      setTemplateCounter(incrementCounter);
      newTemplateIdRef.current = incrementCounter;
    }
  };

  const handleTemplateMount = (
    wrapperElement,
    templateElement,
    setHeightsVersion
  ) => {
    mountTemplate(
      wrapperElement,
      templateElement,
      templateWrappers,
      individualScrollHeights,
      setHeightsVersion,
      scrollContainerRef,
      handleScroll
    );
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const newScrollTop = scrollContainerRef.current.scrollTop;
      setScrollTop(newScrollTop);

      // Scroll direction for follow-up animation
      const scrollDirection =
        newScrollTop > lastScrollTop.current ? "down" : "up";
      lastScrollTop.current = newScrollTop;
      // Handle follow-up container visibility
      const scrollBottom =
        newScrollTop + scrollContainerRef.current.clientHeight >=
        scrollContainerRef.current.scrollHeight - 10;
      animateFollowup(scrollDirection, scrollBottom, followupContainerRef);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerRef.current]);

  useEffect(() => {
    if (newTemplateIdRef.current !== null && questions.length) {
      // Wait for the DOM to update
      const timeoutId = setTimeout(() => {
        const scrollDistance = calculateScrollDistance(
          newTemplateIdRef.current,
          scrollContainerRef
        );

        scrollContainerRef.current.scrollTo({
          top: scrollDistance,
          behavior: "smooth",
        });

        newTemplateIdRef.current = null;
      }, 0); // Execute after the DOM update

      return () => clearTimeout(timeoutId);
    }
  }, [questions.length]);

  return (
    <div className="mainContainer">
      <MainContainer
        open={context?.open}
        handleModalClose={context?.closeModal}
      >
        <div className="scrollContainer" ref={scrollContainerRef}>
          <div className="scroller" />
        </div>
        <CollapseControls handleModalClose={handleModalClose} />
        <div className="templates_Container">
          {questions.map((question, index) => (
            <TemplateWrapper
              onMount={handleTemplateMount}
              scrollContainerRef={scrollContainerRef}
              scrollTop={scrollTop}
              key={index}
              question={question}
              id={index + 1}
              individualScrollHeights={individualScrollHeights}
            />
          ))}
        </div>
        {questions.length > 0 && templateCounter && (
          <FollowUpFullWidth
            templateCounter={templateCounter}
            onSubmit={handleOnSubmit}
            followupContainerRef={followupContainerRef}
            animationClass={
              questions.length ? "animate-fade" : "animate-disappear invisible"
            }
          />
        )}

        <Interim
          onSubmit={handleOnSubmit}
          handleModalClose={handleModalClose}
          animationClass={
            !questions.length ? "animate-fade" : "animate-disappear invisible"
          }
        />
      </MainContainer>
      {!context?.open && (
        <InitialNew open={context?.open} setOpen={context?.openModal} />
      )}
    </div>
  );
};

export default DefaultLayout;
