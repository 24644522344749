import { Flavour, Question, Template } from "../interface";

/*
REQUIREMENTS:

Flavours are template specific used in the order:

Hero > Inform > Inspire > Inform > Inspire > etc

The inform > inspire sequence still applies even after an unrelated question triggers the hero again. So it would go:

Hero > Inform > Hero > Inspire > Inform > Inspire
and NOT
Hero > Inform > Hero > Inform > Inspire > Inform
 */
export default function calculateFlavours(questions: Question[]): Flavour[] {
  let lastFlavour: Flavour;

  let lastAlternatingFlavour: Flavour.inform | Flavour.inspire | Flavour.hero =
    Flavour.inspire;

  const flavours: Flavour[] = [];

  questions.forEach((question, i) => {
    if (
      [
        Template.context_fail,
        Template.safety_fail,
        Template.content_filter_triggered,
      ].includes(question.response.template)
    ) {
      lastFlavour = Flavour.fail;
      flavours.push(Flavour.fail);
      return;
    }

    if (i === 0 || question.response.newTopic || lastFlavour === Flavour.fail) {
      if (lastFlavour === Flavour.hero) {
        lastFlavour = Flavour.inspire;
        flavours.push(Flavour.inspire);
        return;
      }
      lastFlavour = Flavour.hero;
      flavours.push(Flavour.hero);
      return;
    }

    if (lastAlternatingFlavour === Flavour.inspire) {
      lastFlavour = Flavour.inform;
      lastAlternatingFlavour = Flavour.inform;
      flavours.push(Flavour.inform);
      return;
    }

    if (lastAlternatingFlavour === Flavour.inform) {
      lastFlavour = Flavour.inspire;
      lastAlternatingFlavour = Flavour.inspire;
      flavours.push(Flavour.inspire);
      return;
    }
  });

  return flavours;
}
