import React from "react";
import { useState, useEffect, useRef } from "react";

interface TemplateSelectProps {
  formFieldName: string;
  options: string[];
  onChange: (arg: string[]) => void;
  prompt: string;
  testId?: string;
}

export const TemplateSelect = ({
  formFieldName,
  options,
  onChange,
  prompt = "Select one or more options",
  testId,
}: TemplateSelectProps) => {
  const [isJsEnabled, setIsJsEnabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const optionsListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setIsJsEnabled(true);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e?.target?.checked as boolean;
    const option = e.target.value as string;

    const selectedOptionSet = new Set(selectedOptions);

    if (isChecked) {
      selectedOptionSet.add(option);
    } else {
      selectedOptionSet.delete(option);
    }

    const newSelectedOptions = Array.from(selectedOptionSet);

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const isSelectAllEnabled = selectedOptions.length < options.length;

  const handleSelectAllClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (optionsListRef.current) {
      const optionsInputs = optionsListRef.current.querySelectorAll("input");
      optionsInputs.forEach((input) => {
        input.checked = true;
      });
    }

    setSelectedOptions([...options]);
    onChange([...options]);
  };

  const isClearSelectionEnabled = selectedOptions.length > 0;

  const handleClearSelectionClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (optionsListRef.current) {
      const optionsInputs = optionsListRef.current.querySelectorAll("input");
      optionsInputs.forEach((input) => {
        input.checked = false;
      });
    }

    setSelectedOptions([]);
    onChange([]);
  };

  return (
    <label
      id="template-select"
      className="fixed top-0 right-0 z-50"
      data-testid={testId}
    >
      <input id="hidden-checkbox" type="checkbox" className="hidden peer" />

      <div
        id="selected-container"
        className="bg-white cursor-pointer after:content-['▼'] after:text-xs after:ml-1 after:inline-flex after:items-center peer-checked:after:-rotate-180 after:transition-transform inline-flex border rounded px-5 py-2"
      >
        {prompt}
        {isJsEnabled && selectedOptions.length > 0 && (
          <span
            id="selected-length"
            className="ml-1 text-blue-500"
          >{`(${selectedOptions.length} selected)`}</span>
        )}
      </div>

      <div
        id="select-template-list-container"
        className="absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto w-full max-h-60 overflow-y-scroll"
      >
        {isJsEnabled && (
          <ul>
            <li>
              <button
                id="select-all-btn"
                data-testid="TemplateSelect"
                onClick={handleSelectAllClick}
                disabled={!isSelectAllEnabled}
                className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
                aria-label="Select all"
              >
                {"Select All"}
              </button>
            </li>
            <li>
              <button
                id="clear-all-btn"
                onClick={handleClearSelectionClick}
                disabled={!isClearSelectionEnabled}
                className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
                aria-label="Clear selection"
              >
                {"Clear selection"}
              </button>
            </li>
          </ul>
        )}
        <ul id="template-list" ref={optionsListRef}>
          {options.map((option, i) => {
            return (
              <li key={option}>
                <label
                  id={`label-${option}`}
                  className={`flex whitespace-nowrap cursor-pointer px-2 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200`}
                >
                  <input
                    id={`checkbox-${option}`}
                    type="checkbox"
                    // data-testid="TemplateSelect"
                    name={formFieldName}
                    value={option}
                    className="cursor-pointer"
                    onChange={handleChange}
                  />
                  <span id={`text-${option}`} className="ml-1">
                    {option}
                  </span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </label>
  );
};
