import React, { useEffect, useState, useRef } from "react";
import { MovingSpinningCircles } from "../../components/Icons";
import { TypewriterText } from "../../components";
import classnames from "classnames";
import { useConfig } from "../../context/config-context";

interface InitialNewProps {
  open: boolean;
  setOpen: () => void;
}

export default function InitialNew({ open, setOpen }: InitialNewProps) {
  const { config } = useConfig();
  const [textIsDone, setTextIsDone] = useState(false);
  const pillRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);
  const [isStyled, setIsStyled] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsStyled(true);

      const timer = setTimeout(() => {
        setIsStyled(false);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (new URL(event.origin).href !== new URL(config.HOST).href) {
        return;
      }

      if (event.data === "OPEN") {
        setOpen();
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (pillRef.current) {
      observer.observe(pillRef.current);
    }

    return () => {
      pillRef.current && observer.unobserve(pillRef.current);
    };
  }, []);

  return (
    <div
      className={classnames(
        "justify-left flex items-end overflow-hidden h-full"
      )}
    >
      <div
        ref={pillRef}
        className={classnames(
          "flex relative content-center items-center z-50 cursor-pointer text-white bg-black/80 pl-[1.75rem] pr-4 py-4 backdrop-blur-[20px] h-[68px] w-[380px] rounded-t-[50px] rounded-br-[50px] text-left",
          {
            "hidden mb-[30px] ml-[30px]": open,
            "mb-[30px] ml-[30px]": isStyled,
          }
        )}
      >
        <div className={classnames("flex mr-[0.65rem] mt-[20px] justify-left")}>
          <MovingSpinningCircles />
        </div>
        <TypewriterText
          id="initial"
          isDone={textIsDone}
          setIsDone={setTextIsDone}
          className={classnames("text-base whitespace-nowrap overflow-hidden")}
          hasAllText
          text={
            width > 375
              ? "Hello! I'm a conversational AI. How can I help you today?"
              : "Hello! How can I help you today?"
          }
        />
      </div>
    </div>
  );
}
