import React from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import Citations from "../../../components/Citations/Citations";
import { QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface ShortInspireProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  message: {
    templateYScroll: number;
  };
  testingTemplates: boolean;
}

export const ShortInspire = forwardRef(
  ({
    templateOrderIndex,
    response,
    loading,
    testingTemplates,
    message,
  }: ShortInspireProps) => {
    const [version, setVersion] = useState("white");
    const { content, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    const [templateColour, setTemplateColour] = useState("white");
    useEffect(() => {
      if (testingTemplates) {
        if (templateColour === "white") {
          setVersion("white");
        }
        if (templateColour === "dark") {
          setVersion("dark");
        }
        if (templateColour === "grey") {
          setVersion("grey");
        }
      }
    }, [templateColour]);

    return (
      <SharedTemplate
        id="short-inspire"
        textColor={version}
        className="rounded-t-[1.5rem]"
        templateRef={templateRef}
      >
        <div
          className="
          overflow-hidden rounded-t-[1.5rem]"
        >
          <Grid
            testId="short-inspire-grid"
            customClasses="gap-x-4 h-full relative desktop:!mx-0 rounded-t-[1.5rem]"
          >
            <div
              id="short-inspire-content"
              className="text-black h-full mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 flex flex-col"
            >
              &nbsp;
              <div className="h-full">
                <div className="desktop:h-full desktop:flex desktop:flex-col desktop:justify-between mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0">
                  <div>
                    <h2
                      id="short-inspire-tagline"
                      className="mobile:text-[1rem] desktop:text-lg font-medium uppercase"
                    >
                      {query}
                    </h2>
                    {testingTemplates && (
                      <ColourSelect
                        templateColour={templateColour}
                        setTemplateColour={setTemplateColour}
                      ></ColourSelect>
                    )}
                    <div className="pt-[36px]">
                      {!content.header ? (
                        <TitleSkeleton />
                      ) : (
                        <h3
                          id="short-inspire-header"
                          className="mobile:text-4xl desktop:text-[40px] font-normal"
                        >
                          {content.header}
                        </h3>
                      )}
                    </div>
                    <div
                      id="short-inspire-paragraph-content-1"
                      className="mobile:pt-[2.25rem] desktop:pt-[2.75rem]"
                    >
                      {!content.paragraphs[0] ? (
                        <>
                          <div className="mobile:block tablet:hidden desktop:block">
                            <ParagraphSkeleton />
                          </div>
                          <div className="mobile:hidden tablet:block desktop:hidden">
                            <ParagraphSkeleton lines="tablet" />
                          </div>
                        </>
                      ) : (
                        <TypewriterText
                          id="short-inspire-paragraph-1"
                          className="mobile:text-sm desktop:text-lg font-light"
                          hasAllText={content.paragraphs[0].done}
                          text={content.paragraphs[0].text}
                          isDone={para1Done}
                          setIsDone={setPara1Done}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="mobile:pt-[36px] desktop:pt-0">
                  {!content.imgURL ? (
                    <ImageSkeleton display="mobile" />
                  ) : (
                    <div
                      style={{
                        backgroundImage: `url('${content.imgURL}')`,
                      }}
                      className="w-full max-h-[450px] mobile:h-[500px] tablet:h-[450px] bg-no-repeat desktop:hidden bg-cover bg-center"
                    ></div>
                  )}
                </div>
              </div>
            </div>

            {/* Desktop only image */}
            <div
              id="short-inspire-desktop-image-container"
              className="desktop:col-start-13 mobile:hidden desktop:block desktop:col-span-12 w-full h-full row-start-1 z-30"
            >
              {!content.imgURL ? (
                <ImageSkeleton display="desktop" />
              ) : (
                <>
                  {!content.imgURL ? (
                    <div className="w-full h-screen max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                  ) : (
                    <div
                      id="short-inspire-desktop-image"
                      className="pt-[144px] pr-[96px]"
                    >
                      <img src={content.imgURL} />
                    </div>
                  )}
                </>
              )}
            </div>
          </Grid>
          {para1Done && (
            <div>
              <Citations
                metadata={response.metadata}
                version={version}
                usedSnippetIds={response.used_snippet_ids}
              />
              <MediaLinksV2
                assets={assets}
                display="fullwidth"
                version={version}
              />
            </div>
          )}
        </div>
      </SharedTemplate>
    );
  }
);
