import { Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import Citations from "../../../components/Citations/Citations";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface LongInspireProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  testingTemplates: boolean;
  message: {
    templateYScroll: number;
  };
}

export const LongInspire = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      testingTemplates,
      message,
    }: LongInspireProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    const [templateColour, setTemplateColour] = useState("white");

    return (
      <SharedTemplate
        id="long-inspire"
        textColor={version}
        className="relative rounded-t-[1.5rem] z-40"
        templateRef={templateRef}
      >
        <Grid
          customClasses={classnames(
            "gap-x-4 desktop:!mx-0 relative rounded-t-[1.5rem] desktop:-mt-[3rem]",
            {
              ["bg-transparent"]:
                version === "white" || version === "grey" || version === "dark",
            }
          )}
        >
          <div
            id="long-inspire-image-contaier"
            className="col-start-1 row-start-1 col-span-full h-full z-40"
          >
            {!content.imgURL ? (
              <div>
                <div className="mobile:hidden desktop:block">
                  <ImageSkeleton display="desktop-top" />
                </div>
                <div className="desktop:hidden">
                  <ImageSkeleton display="mobile" />
                </div>
              </div>
            ) : (
              <>
                {!content.imgURL ? (
                  <div className="rounded-t-[1.5rem] mobile:w-full mobile:max-h-full mobile:min-h-[450px] bg-gradient-to-b from-[#8B919A] to-white"></div>
                ) : (
                  <div className="relative max-h-full mt-[-6px]">
                    <div
                      id="long-inspire-image-overlay"
                      className="bg-gradient-to-r from-[black]/60 from-60% rounded-t-[1.5rem] z-30 absolute top-0 left-0 w-full h-full max-h-full mobile:min-h-[400px]"
                    ></div>
                    <div
                      id="long-inspire-image"
                      style={{
                        backgroundImage: `url('${content.imgURL}')`,
                      }}
                      className={`rounded-t-[1.5rem] z-20 relative w-full max-h-full mobile:min-h-[400px] bg-cover bg-center bg-no-repeat`}
                    ></div>
                    <div className="absolute h-full w-full overflow-hidden top-0">
                      <Grid customClasses="z-40 gap-x-4 h-full relative !mx-0">
                        <div
                          id="long-inspire-header-content"
                          className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full mobile:row-start-1 mobile:pt-[4.25rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:w-full desktop:w-1/2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                        >
                          <h2
                            id="long-inspire-tagline"
                            className="text-white mobile:text-[1rem] desktop:text-lg font-medium uppercase"
                          >
                            {query}
                          </h2>
                          {testingTemplates && (
                            <ColourSelect
                              templateColour={templateColour}
                              setTemplateColour={setTemplateColour}
                            ></ColourSelect>
                          )}
                          {!content.header ? (
                            <TitleSkeleton />
                          ) : (
                            <h3
                              id="long-inspire-header"
                              className="text-white mobile:py-[2.25rem] tablet:py-[1.5rem] desktop:py-[36px] mobile:text-4xl tablet:text-[40px] font-bold"
                            >
                              {content.header}
                            </h3>
                          )}
                        </div>
                      </Grid>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Grid>
        <Grid customClasses="gap-x-4 desktop:!mx-0 relative">
          <div
            id="long-inspire-left-answer-content"
            className="z-10 mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-span-8 mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[2.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:flex desktop:flex-col desktop:pb-0"
          >
            {!content.tagline ? (
              <ParagraphSkeleton />
            ) : (
              <h3 id="long-inspire-paragraph-1" className="text-2xl font-light">
                {content.tagline}
              </h3>
            )}
          </div>

          <div
            id="long-inspire-right-answer-content"
            className="mobile:col-start-1 desktop:col-start-11 mobile:col-span-full desktop:col-end-24 w-full h-full mobile:pt-[2.25rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <div>
              {!content.paragraphs[0] ? (
                <ParagraphSkeleton />
              ) : (
                <TypewriterText
                  id="long-inspire-paragraph-2"
                  className="mobile:text-sm desktop:text-lg font-light"
                  hasAllText={content.paragraphs[0].done}
                  text={content.paragraphs[0].text}
                  isDone={para1Done}
                  setIsDone={setPara1Done}
                />
              )}
            </div>
            {!content.paragraphs[1] ? (
              <ParagraphSkeleton />
            ) : (
              para1Done && (
                <div className="pt-[1.375rem]">
                  <TypewriterText
                    id="long-inspire-paragraph-2"
                    className="mobile:text-sm desktop:text-lg font-light"
                    hasAllText={content.paragraphs[1].done}
                    text={content.paragraphs[1].text}
                    isDone={para2Done}
                    setIsDone={setPara2Done}
                  />
                </div>
              )
            )}
          </div>
        </Grid>
        <div>
          {para1Done && (
            <Citations
              metadata={response.metadata}
              version={version}
              usedSnippetIds={response.used_snippet_ids}
            />
          )}
          {para1Done && (
            <MediaLinksV2
              assets={assets}
              display="fullwidth"
              version={version}
            />
          )}
        </div>
      </SharedTemplate>
    );
  }
);
