import clone from "lodash.clonedeep";
import {
  Chunk,
  ChunkType,
  ChatChunk,
  FollowUpsChunk,
  UserQuestionChunk,
  QuestionResponse,
  Template,
  Config,
  ContextMetadataItem,
} from "../interface";
import { submitFeedback } from "./feedback";

const DELIMITER = "†";
const DELIMITER_LONG = "\n\n";
let three_parts_status = "header";

export default function processChunk(
  conversation_id: string,
  config: Config,
  chunk: Chunk,
  _response: QuestionResponse
): QuestionResponse {
  // we do this to avoid any weird issues with React & mutated state
  let response = clone(_response);
  switch (chunk.chunk_type) {
    case ChunkType.header: {
      // this will only ever be preset to true when it's the first question
      if (!response.newTopic) {
        response.newTopic =
          chunk.data.check_continuity.startsWith("not_related");
      }

      response.used_snippet_ids = chunk.data.used_snippet_ids;
      response.messageId = chunk.message_id;
      response.template = chunk.data.decide_template.startsWith(
        "content_filter_triggered"
      )
        ? Template.content_filter_triggered
        : chunk.data.decide_template;

      let headlines = chunk.data.headlines.split("|");

      // catch funky delimiters
      if (headlines.length === 1) {
        headlines = chunk.data.headlines.split("\n\n");
        if (headlines.length === 2) {
          console.log(`CAUGHT HEADLINES DELIMITER:`, chunk.data.headlines);
        }
      }
      if (headlines.length === 1) {
        headlines = chunk.data.headlines.split("' ");
        if (headlines.length === 2) {
          console.log(`CAUGHT HEADLINES DELIMITER:`, chunk.data.headlines);
        }
      }
      if (headlines.length === 1) {
        headlines = chunk.data.headlines.split(" '");
        if (headlines.length === 2) {
          console.log(`CAUGHT HEADLINES DELIMITER:`, chunk.data.headlines);
        }
      }

      if (headlines.length === 2) {
        response.content.header = headlines[0];
        response.content.tagline = headlines[1];
      } else {
        console.log(`FAILED HEADLINES DELIMITER:`, chunk.data.headlines);
        response.content.header = headlines[0];
        // prevents skeleton
        response.content.tagline = " ";
      }

      // remove stray delimiters
      response.content.header = response.content.header.replaceAll(
        DELIMITER,
        ""
      );
      response.content.tagline = response.content.tagline.replaceAll(
        DELIMITER,
        ""
      );

      response.timestamp = chunk.timestamp;
      response.metadata = filterDuplicateContextMetadata(
        chunk.data.context_metadata
      );
      response.assets = chunk.data.assets_data;
      response.content.imgURL =
        chunk.data.assets_data?.images?.landscape?.url ||
        chunk.data.assets_data?.images?.square?.url ||
        chunk.data.assets_data?.images?.portrait[0]?.url;

      window.parent.postMessage(
        ["SET_MESSAGE_ID", chunk.message_id],
        config.HOST
      );

      console.log({
        query: response.query,
        message_id: chunk.message_id,
        conversation_id: chunk.conversation_id,
        timestamp: new Date().toISOString(),
      });

      break;
    }
    case ChunkType.chat_content: {
      const text = (chunk as ChatChunk).data;

      if (response.template === Template.three_parts) {
        if (!response.content.paragraphs.length) {
          response.content.paragraphs.push({
            text: "",
            header: text,
            done: false,
          });
        } else if (three_parts_status === "header" && text.includes("|")) {
          three_parts_status = "text";
        } else if (text.includes(DELIMITER)) {
          three_parts_status = "header";
          response.content.paragraphs[
            response.content.paragraphs.length - 1
          ].done = true;
          response.content.paragraphs.push({
            text: "",
            header: "",
            done: false,
          });
        } else {
          if (three_parts_status === "header") {
            response.content.paragraphs[
              response.content.paragraphs.length - 1
            ] = {
              header:
                response.content.paragraphs[
                  response.content.paragraphs.length - 1
                ].header + text,
              text: "",
              done: false,
            };
          } else {
            response.content.paragraphs[
              response.content.paragraphs.length - 1
            ] = {
              header:
                response.content.paragraphs[
                  response.content.paragraphs.length - 1
                ].header,
              text:
                response.content.paragraphs[
                  response.content.paragraphs.length - 1
                ].text + text,
              done: false,
            };
          }
        }
      } else {
        if (!response.content.paragraphs.length) {
          response.content.paragraphs.push({ text, done: false });
        } else if (
          text.includes(
            response.template === Template.long ? DELIMITER_LONG : DELIMITER
          )
        ) {
          response.content.paragraphs[
            response.content.paragraphs.length - 1
          ].done = true;
          response.content.paragraphs.push({ text: "", done: false });
        } else {
          response.content.paragraphs[response.content.paragraphs.length - 1] =
          {
            done: false,
            text:
              response.content.paragraphs[
                response.content.paragraphs.length - 1
              ].text + text,
          };
        }
      }
      break;
    }
    case ChunkType.follow_ups: {
      // we already loaded the last available ones so don't overwrite them with an empty array
      if ((chunk as FollowUpsChunk).data.length) {
        response.followUps = (chunk as FollowUpsChunk).data;
      }
      break;
    }
    case ChunkType.user_question: {
      response.userQuestion = (chunk as UserQuestionChunk).data;
      break;
    }
    case ChunkType.chat_finish: {
      response = cleanUpParagraphs(response, conversation_id, config);
      three_parts_status = "header";
      console.log("QUESTION_RESPONSE_FINISH", {
        response: response,
      });
      break;
    }
    case ChunkType.request_finish: {
      // if we send the wrong client & site IDs to Satalia we get a 200 with this chunk on its own
      if (chunk.data === "error") {
        submitFeedback({
          config,
          feedback_type: -1,
          conversation_id,
          message_id: response.messageId,
        });
        // force fail template
        response.template = Template.context_fail;
        response.content.paragraphs = [
          {
            text: "Sorry, something went wrong. Please try again later.",
            done: true,
          },
        ];
      }
    }
  }

  return response;
}

function cleanUpParagraphs(
  _response: QuestionResponse,
  conversation_id: string,
  config: Config
) {
  feedback(config, conversation_id, _response);

  // we do this to avoid any weird issues with React & mutated state
  let response = clone(_response);

  // due to trailing delimiters we can end up with extra empt paragraphs in the array, they don't hurt anything except the logging below, so just cleaning up for cleaner logs
  if (response.template === Template.short) {
    response.content.paragraphs.splice(1);
  } else if (response.template === Template.long) {
    response.content.paragraphs.splice(2);
  } else if (response.template === Template.three_parts) {
    response.content.paragraphs.splice(3);
  }

  // ensure no falsy values in existing data
  response.content.paragraphs.forEach((p, i) => {
    if (response.template === Template.three_parts) {
      if (!p.header) {
        console.log("CAUGHT MISSING PARAGRAPH HEADER");
        response.content.paragraphs[i].header = " ";
      }
    }
    if (!p.text) {
      console.log("CAUGHT MISSING PARAGRAPH TEXT");
      response.content.paragraphs[i].text = " ";
    }
  });

  // push missing paragraphs
  if (response.template === Template.short) {
    while (response.content.paragraphs.length < 1) {
      console.log("CAUGHT MISSING PARAGRAPH");
      response.content.paragraphs.push({ text: " ", done: true });
    }
  }

  if (response.template === Template.long) {
    while (response.content.paragraphs.length < 2) {
      console.log("CAUGHT MISSING PARAGRAPH");
      response.content.paragraphs.push({ text: " ", done: true });
    }
  }

  if (response.template === Template.three_parts) {
    while (response.content.paragraphs.length < 3) {
      console.log("CAUGHT MISSING PARAGRAPH");
      response.content.paragraphs.push({ header: " ", text: " ", done: true });
    }
  }

  // ensure all paragraphs are marked as done
  response.content.paragraphs = response.content.paragraphs.map((p) => ({
    ...p,
    done: true,
    text: p.text.replaceAll(DELIMITER, ""),
  }));

  return response;
}

function filterDuplicateContextMetadata(
  metadata: ContextMetadataItem[]
): ContextMetadataItem[] {
  const seenDocIds = new Set<string>();
  return metadata.filter((item) => {
    if (seenDocIds.has(item.thumbnail)) {
      return false;
    }
    seenDocIds.add(item.thumbnail);
    return true;
  });
}

async function feedback(
  config: Config,
  conversation_id: string,
  response: QuestionResponse
) {
  try {
    if (isError(response)) {
      submitFeedback({
        config,
        feedback_type: -1,
        conversation_id,
        message_id: response.messageId,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function isError(response: QuestionResponse) {
  if (
    ![Template.short, Template.long, Template.three_parts].includes(
      response.template
    )
  ) {
    return true;
  }
  if (
    Template.short === response.template &&
    (response.content.paragraphs.length < 1 ||
      !response.content.paragraphs[0].text.length)
  ) {
    return true;
  }
  if (
    Template.long === response.template &&
    (response.content.paragraphs.length < 2 ||
      !response.content.paragraphs[0].text.length ||
      !response.content.paragraphs[1].text.length)
  ) {
    return true;
  }
  if (
    Template.three_parts === response.template &&
    (response.content.paragraphs.length < 3 ||
      !response.content.paragraphs[0].header?.length ||
      !response.content.paragraphs[0].text?.length ||
      !response.content.paragraphs[1].header?.length ||
      !response.content.paragraphs[1].text?.length ||
      !response.content.paragraphs[2].header?.length ||
      !response.content.paragraphs[2].text?.length)
  ) {
    return true;
  }
  return false;
}
