import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";
import { useConfig } from "../context/config-context";

// Define the type for the context value
interface ModalContextType {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
  openInterim: boolean;
  setInterim: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined
);

// Define the type for the provider's children prop
interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const { config } = useConfig();

  const [open, setOpen] = useState<boolean>(false);
  const [openInterim, setInterim] = useState<boolean>(true);

  const openModal = useCallback(() => {
    setOpen(true);
    setInterim(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      window.parent.postMessage("CLOSE", config.HOST);
    }, 250);
  }, [config]);

  return (
    <ModalContext.Provider
      value={{ open, openModal, closeModal, openInterim, setInterim }}
    >
      {children}
    </ModalContext.Provider>
  );
};

// Custom hook to use the modal context, throwing an error if used outside of provider
export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
