import { ChatProvider } from "./context/chat-context";
import { ConfigProvider } from "./context/config-context";
import { ModalProvider } from "./hooks/useModal";
import "./index.css";
import { Helmet } from "react-helmet";

export const Root = ({ children }) => {
  return (
    <>
      {document.location.hostname === "gxstudiofestg.demosjr.com" && (
        <Helmet>
          <script
            type="text/javascript"
            // src="https://www.bugherd.com/sidebarv2.js?apikey=4ne3aixs2saepfxa61scrw"
            async={true}
          ></script>
        </Helmet>
      )}
      <ConfigProvider>
        <ModalProvider>
          <ChatProvider>{children}</ChatProvider>
        </ModalProvider>
      </ConfigProvider>
    </>
  );
};
