import React from "react";

export const StaticSpinningCircles = () => (
  <svg
    width="97"
    height="90"
    viewBox="0 0 97 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 2608">
      <g id="Group 2875">
        <g id="Group 2867">
          <g id="Ellipse 2" filter="url(#filter0_f_4114_492)">
            <ellipse
              cx="45.0098"
              cy="44.9903"
              rx="27.0099"
              ry="27.0098"
              fill="url(#paint0_linear_4114_492)"
            />
          </g>
        </g>
        <g id="Group 2868">
          <path
            id="Rectangle 34624171"
            d="M49.213 28.6444C49.0751 28.3053 48.5833 28.3053 48.4453 28.6444L45.9233 34.846C45.4294 36.0602 44.4441 37.0218 43.2 37.5037L36.8454 39.9651C36.498 40.0997 36.498 40.5796 36.8454 40.7142L43.2 43.1756C44.4441 43.6575 45.4294 44.6191 45.9233 45.8333L48.4453 52.0349C48.5832 52.374 49.0751 52.374 49.213 52.0349L51.7351 45.8334C52.2289 44.6191 53.2142 43.6575 54.4584 43.1756L60.8129 40.7143C61.1603 40.5797 61.1604 40.0997 60.8129 39.9651L54.4584 37.5037C53.2142 37.0218 52.2289 36.0602 51.7351 34.846L49.213 28.6444Z"
            fill="white"
          />
          <path
            id="Rectangle 34624172"
            d="M42.1526 51.6132C42.0146 51.2741 41.5228 51.2741 41.3849 51.6132L41.0387 52.4645C40.5449 53.6787 39.5596 54.6403 38.3154 55.1222L37.4431 55.4601C37.0956 55.5947 37.0956 56.0747 37.4431 56.2092L38.3154 56.5471C39.5596 57.029 40.5449 57.9906 41.0387 59.2049L41.3849 60.0562C41.5228 60.3953 42.0146 60.3953 42.1525 60.0562L42.4988 59.2049C42.9926 57.9906 43.9779 57.029 45.2221 56.5471L46.0943 56.2093C46.4418 56.0747 46.4418 55.5947 46.0943 55.4601L45.2221 55.1222C43.9779 54.6403 42.9926 53.6787 42.4988 52.4645L42.1526 51.6132Z"
            fill="white"
          />
          <path
            id="Rectangle 34624173"
            d="M56.8724 47.0209C56.7345 46.6818 56.2427 46.6818 56.1048 47.0209L55.7586 47.8722C55.2647 49.0864 54.2794 50.048 53.0353 50.5299L52.163 50.8678C51.8155 51.0024 51.8155 51.4824 52.163 51.617L53.0353 51.9548C54.2794 52.4368 55.2647 53.3983 55.7586 54.6126L56.1048 55.4639C56.2427 55.803 56.7345 55.803 56.8724 55.4639L57.2186 54.6126C57.7124 53.3983 58.6977 52.4368 59.9419 51.9548L60.8142 51.617C61.1617 51.4824 61.1617 51.0024 60.8142 50.8678L59.9419 50.5299C58.6977 50.048 57.7124 49.0864 57.2186 47.8722L56.8724 47.0209Z"
            fill="white"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_4114_492"
        x="0.650608"
        y="0.631077"
        width="88.7185"
        height="88.7183"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="8.6747"
          result="effect1_foregroundBlur_4114_492"
        />
      </filter>
      <filter
        id="filter1_f_4114_492"
        x="24.7032"
        y="0.236546"
        width="71.3692"
        height="71.3692"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="8.6747"
          result="effect1_foregroundBlur_4114_492"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4114_492"
        x1="45.0098"
        y1="17.9805"
        x2="45.0098"
        y2="72.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FADBAC" />
        <stop offset="1" stop-color="#006EE8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4114_492"
        x1="60.3878"
        y1="17.586"
        x2="60.3878"
        y2="54.2563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#FEAF91" />
      </linearGradient>
    </defs>
  </svg>
);
