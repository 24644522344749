import React from "react";
import classnames from "classnames";
import { TypewriterText } from "../TypewriterText/TypewriterText";

interface ButtonProps {
  style: "primary" | "secondary" | "tertiary" | "active";
  classes: string;
  onClick: () => void;
  text: string;
  typewriter?: {
    id: string;
    isDone: boolean;
    setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
    textReady: boolean;
  };
}

export const Button = ({
  style,
  classes,
  onClick,
  text,
  typewriter,
}: ButtonProps) => {
  const primary = classnames(
    "bg-button-bg-default mobile:text-sm text-button-txt-default border border-button-bdr-default focus-visible:bg-button-bg-hover hover:bg-button-bg-hover hover:border-button-bdr-hover mobile:px-[22px] mobile:py-[12px] desktop:px-[28px] desktop:py-[12px]",
    classes
  );

  const secondary = classnames(
    "bg-white/50 mobile:text-sm text-grey-1000 border border-grey-400 hover:bg-grey-400 mobile:px-[22px] mobile:py-[12px] desktop:px-[28px] desktop:py-[12px]",
    classes
  );

  const tertiary = classnames(
    "bg-grey-900 mobile:text-sm text-grey-400 border border-grey-700 hover:bg-grey-1000/50 mobile:px-[22px] mobile:py-[12px] desktop:px-[28px] desktop:py-[12px]",
    classes
  );

  const active = classnames(
    "bg-button-bg-active mobile:text-sm text-button-txt-active border border-button-bdr-active mobile:px-[22px] mobile:py-[12px] desktop:px-[28px] desktop:py-[12px]",
    classes
  );
  return (
    <button
      aria-label={text}
      id="follow-up-button"
      type="button"
      className={classnames(
        style === "primary" && primary,
        style === "secondary" && secondary,
        style === "tertiary" && tertiary,
        style === "active" && active,
        classes,
        "rounded-3xl focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-button-bdr-default font-normal"
      )}
      onClick={onClick}
    >
      {typewriter?.id
        ? typewriter.textReady && (
            <TypewriterText
              id={typewriter.id}
              hasAllText
              isDone={typewriter.isDone}
              setIsDone={typewriter.setIsDone}
              text={text}
            />
          )
        : text}
    </button>
  );
};
