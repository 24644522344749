import React from "react";
import useMediaQuery from "../../hooks/useMediaQuery";

export const Cross = () => {
  const isMobile = useMediaQuery("(max-width: 480px)");

  return (
    <>
      {isMobile && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="26" height="26" rx="13" fill="#F5F5F5" />
          <path
            d="M8 18L18 8.00004M18 18L8.00004 8"
            stroke="#808080"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      )}
      {!isMobile && (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="26" height="26" rx="13" fill="#F5F5F5" />
          <path
            d="M8 18L18 8.00004M18 18L8.00004 8"
            stroke="#808080"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      )}
    </>
  );
};
